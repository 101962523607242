import React, { useState, useEffect } from "react";
import axios from "axios";
import config from "../config";
import NotificationCart from "./NotificationCart";

const EditRestaurant = () => {
  const [restaurant, setRestaurant] = useState({});
  const [notificationMessage, setNotificationMessage] = useState("");
  const [notificationStatus, setNotificationStatus] = useState("");
  const [isNotification, setIsNotification] = useState(false);
  const [formData, setFormData] = useState({
    restaurant_name: "",
    address: "",
    email: "",
    phone: "",
    restaurant_type: "",
    number_of_tables: 0,
    opening_hours: {
      monday: {
        closing_day: "",
        open: "",
        close: "",
        pickup: false,
      },
      tuesday: {
        closing_day: "",
        open: "",
        close: "",
        pickup: false,
      },
      wednesday: {
        closing_day: "",
        open: "",
        close: "",
        pickup: false,
      },
      thursday: {
        closing_day: "",
        open: "",
        close: "",
        pickup: false,
      },
      friday: {
        closing_day: "",
        open: "",
        close: "",
        pickup: false,
      },
      saturday: {
        closing_day: "",
        open: "",
        close: "",
        pickup: false,
      },
      sunday: {
        closing_day: "",
        open: "",
        close: "",
        pickup: false,
      },
    },
    city: "",
    state: "",
    country: "",
    zip_code: "",
    user_id: "",
  });

  useEffect(() => {
    fetchRestaurant();
    console.log("formData :------", formData);
  }, []);

  const fetchRestaurant = async () => {
    const response = await axios.get(
      `${
        config.API_BASE_URL
      }/restaurant/get-restaurant/${sessionStorage.getItem("restaurant_id")}`
    );
    const data = response.data.data;
    console.log("data", response.data);

    setRestaurant(data);
    setFormData({
      restaurant_name: data.restaurant_name,
      address: data.address,
      email: data.email,
      phone: data.phone,
      restaurant_type: data.restaurant_type,
      number_of_tables: Number(data.number_of_tables),
      opening_hours: {
        monday: {
          open: data.opening_hours.monday.open,
          close: data.opening_hours.monday.close,
          closing_day: data.opening_hours.monday.closing_day || "",
          pickup: data.opening_hours.monday.pickup,
        },
        tuesday: {
          open: data.opening_hours.tuesday.open,
          close: data.opening_hours.tuesday.close,
          closing_day: data.opening_hours.tuesday.closing_day || "",
          pickup: data.opening_hours.tuesday.pickup,
        },
        wednesday: {
          open: data.opening_hours.wednesday.open,
          close: data.opening_hours.wednesday.close,
          closing_day: data.opening_hours.wednesday.closing_day || "",
          pickup: data.opening_hours.wednesday.pickup,
        },
        thursday: {
          open: data.opening_hours.thursday.open,
          close: data.opening_hours.thursday.close,
          closing_day: data.opening_hours.thursday.closing_day || "",
          pickup: data.opening_hours.thursday.pickup,
        },
        friday: {
          open: data.opening_hours.friday.open,
          close: data.opening_hours.friday.close,
          closing_day: data.opening_hours.friday.closing_day || "",
          pickup: data.opening_hours.friday.pickup,
        },
        saturday: {
          open: data.opening_hours.saturday.open,
          close: data.opening_hours.saturday.close,
          closing_day: data.opening_hours.saturday.closing_day || "",
          pickup: data.opening_hours.saturday.pickup,
        },
        sunday: {
          open: data.opening_hours.sunday.open,
          close: data.opening_hours.sunday.close,
          closing_day: data.opening_hours.sunday.closing_day || "",
          pickup: data.opening_hours.sunday.pickup,
        },
      },
      city: data.city,
      state: data.state,
      country: data.country,
      zip_code: data.zip_code,
      user_id: sessionStorage.getItem("user_id"),
    });
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    if (name.startsWith("opening_hours.")) {
      const [_, day, field] = name.split(".");
      setFormData((prev) => {
        const updatedHours = {
          ...prev.opening_hours[day],
          [field]: type === "checkbox" ? checked : value,
        };
        if (field === "close") {
          const closingTime = value;
          const [hours, minutes] = closingTime.split(":");
          const isAM = parseInt(hours, 10) < 6;
          if (isAM) {
            const days = Object.keys(prev.opening_hours);
            const currentDayIndex = days.indexOf(day);
            const nextDay = days[(currentDayIndex + 1) % days.length];
            updatedHours.closing_day = nextDay;
          } else {
            updatedHours.closing_day = day;
          }
        }

        return {
          ...prev,
          opening_hours: {
            ...prev.opening_hours,
            [day]: updatedHours,
          },
        };
      });
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [name]: type === "checkbox" ? checked : value,
      }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const dataToSubmit = {
      ...formData,
      opening_hours: formData.opening_hours,
    };
    try {
      const response = await axios.put(
        `${config.API_BASE_URL}/restaurant/update-restaurant/${restaurant.restaurant_id}`,
        dataToSubmit
      );
      if (response.status === 200) {
        setNotificationMessage(response.data.message);
        setIsNotification(true);
        setNotificationStatus("sucess");
        setTimeout(() => {
          setIsNotification(false);
        }, 9000);
      } else {
        throw new Error("Error updating restaurant");
      }
    } catch (error) {
      console.error("Error updating restaurant:", error);
      setNotificationMessage("Error updating restaurant");
      setIsNotification(true);
      setNotificationStatus("error");
      setTimeout(() => {
        setIsNotification(false);
      }, 9000);
    }
  };

  return (
    <div className="max-w-3xl mx-auto p-6 bg-white rounded-xl shadow-lg">
      <h2 className="text-2xl font-bold text-gray-800 text-center mb-8">
        Edit Restaurant
      </h2>

      {isNotification && (
        <NotificationCart
          message={notificationMessage}
          status={notificationStatus}
        />
      )}

      <form onSubmit={handleSubmit} className="space-y-6">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          {/* Basic Information */}
          <div className="space-y-4">
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1 text-left">
                Restaurant Name
              </label>
              <input
                type="text"
                name="restaurant_name"
                value={formData.restaurant_name}
                onChange={handleChange}
                required
                className="w-full p-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1 text-left">
                Email
              </label>
              <input
                type="email"
                name="email"
                value={formData.email}
                readOnly
                className="w-full p-2 bg-gray-100 border border-gray-300 rounded-md cursor-not-allowed"
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1 text-left">
                Phone
              </label>
              <input
                type="text"
                name="phone"
                value={restaurant.phone}
                readOnly
                className="w-full p-2 bg-gray-100 border border-gray-300 rounded-md cursor-not-allowed"
              />
            </div>
          </div>

          {/* Address Information */}
          <div className="space-y-4">
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1 text-left">
                Address
              </label>
              <input
                type="text"
                name="address"
                value={formData.address}
                readOnly
                className="w-full p-2 bg-gray-100 border border-gray-300 rounded-md cursor-not-allowed"
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1 text-left">
                City
              </label>
              <input
                type="text"
                name="city"
                value={formData.city}
                readOnly
                className="w-full p-2 bg-gray-100 border border-gray-300 rounded-md cursor-not-allowed"
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1 text-left">
                Country
              </label>
              <input
                type="text"
                name="country"
                value={formData.country}
                readOnly
                className="w-full p-2 bg-gray-100 border border-gray-300 rounded-md cursor-not-allowed"
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1 text-left">
                State
              </label>
              <input
                type="text"
                name="state"
                value={formData.state}
                readOnly
                className="w-full p-2 bg-gray-100 border border-gray-300 rounded-md cursor-not-allowed"
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1 text-left">
                Zip Code
              </label>
              <input
                type="number"
                name="zip_code"
                value={formData.zip_code}
                readOnly
                className="w-full p-2 bg-gray-100 border border-gray-300 rounded-md cursor-not-allowed"
              />
            </div>
          </div>
        </div>

        {/* Restaurant Details */}
        <div className="space-y-4">
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1 text-left">
              Restaurant Type
            </label>
            <input
              type="text"
              name="restaurant_type"
              value={formData.restaurant_type}
              onChange={handleChange}
              required
              className="w-full p-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1 text-left">
              Number of Tables
            </label>
            <input
              type="number"
              name="number_of_tables"
              value={formData.number_of_tables}
              onChange={handleChange}
              required
              min="0"
              className="w-full p-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
            />
          </div>
        </div>

        {/* Opening Hours */}
        <div className="space-y-4">
          <h3 className="text-lg font-semibold text-gray-800 text-left">
            Opening Hours
          </h3>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
            {Object.entries(formData.opening_hours).map(([day, hours]) => (
              <div key={day} className="p-4 bg-gray-50 rounded-lg space-y-3">
                <span className="block font-medium text-gray-700">
                  {day.charAt(0).toUpperCase() + day.slice(1)}
                </span>
                <div className="space-y-2">
                  <input
                    type="time"
                    name={`opening_hours.${day}.open`}
                    value={hours.open}
                    onChange={handleChange}
                    className="w-full p-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                  />
                  <input
                    type="time"
                    name={`opening_hours.${day}.close`}
                    value={hours.close}
                    onChange={handleChange}
                    className="w-full p-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                  />
                  <label className="flex items-center gap-2">
                    <input
                      type="checkbox"
                      name={`opening_hours.${day}.pickup`}
                      checked={hours.pickup}
                      onChange={handleChange}
                      className="rounded border-gray-300 text-blue-600 focus:ring-blue-500"
                    />
                    <span className="text-sm text-gray-600">
                      Pickup Available
                    </span>
                  </label>
                </div>
              </div>
            ))}
          </div>
        </div>

        <button
          type="submit"
          className="w-full py-3 px-4 bg-[#abbfff] text-white font-semibold rounded-lg shadow-md hover:bg-[#4e7ab4] focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 transition-colors"
        >
          Update Restaurant
        </button>
      </form>
    </div>
  );
};

export default EditRestaurant;
